<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        style="position: relative; min-height: 50vh"
        v-if="
          this.$route.name != 'Event Details' &&
          this.$route.name != 'Event Order'
        "
      >
        <div
          class="header-auth position-absolute pb-30 pt-30 scroll0 w-100 blur"
          style="filter: blur(5px); -webkit-filter: blur(5px); height: 100%"
          :style="{
            backgroundImage:
              'url(' + require('@/assets/img/markt-oelsnitz.jpeg') + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
          }"
        ></div>
        <div style="z-index: 2; position: absolute; height: 100%; width: 100%">
          <v-container fluid fill-height>
            <v-row class="d-flex mt-5" align="center">
              <v-col cols="12" md="6" class="mx-auto py-0">
                <h2
                  class="text-h2 font-weight-bold text-white text-center mb-2"
                  v-if="this.$route.name == 'Pricing'"
                >
                  {{ headerTitle() }}
                </h2>
                <h1
                  class="text-h3 text-white font-weight-bolder text-center mb-2 mt-5"
                  v-else
                >
                  {{ headerTitle() }}
                </h1>
                <p
                  class="text-h5 text-white text-center font-weight-thin mb-12"
                >
                  {{ paragraphs }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>

      <div v-else>
        <!-- <v-img
          v-if="eventData"
          :key="eventData._id"
          width="100%"
          :src="getImage"
        ></v-img> -->
      </div>
      <app-bar-auth
        :login="loginFromSub"
        background="transparent"
        has-bg
        linkColor="white"
      >
      </app-bar-auth>
      <v-container fluid class="pb-0 px-0 mt-5">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <router-view @login="loginFromSub = true"></router-view>Ç
        </fade-transition>
        <div class="mt-15">
          <content-footer></content-footer>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import AppBarAuth from "@/components/AppBarAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarAuth,
    FadeTransition,
    ContentFooter,
  },
  computed: {
    eventData() {
      return this.$store.getters.event;
    },
    getImage() {
      if (this.eventData) {
        return process.env.VUE_APP_API_BASEURL + "/" + this.eventData.img;
      }
      return "";
    },
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
      loginFromSub: false,
    };
  },
  mounted() {
    console.log("mounted!");
    console.log(this.$route.name);
  },
  methods: {
    headerTitle() {
      console.log(this.$route.name);
      switch (this.$route.name) {
        case "Home":
          this.paragraphs =
            "Deine Buchungsplattform für Veranstaltungen in Oelsnitz/Vogtl. und Umgebung !";
          return "Oelsnitz-Ticket.de - komplizierte Buchungen waren gestern.";
        case "Events":
          this.paragraphs = "Buche Dein nächstes Event";
          return "Veranstaltungen";
        case "Event Details":
          this.paragraphs = "Buche Dein nächstes Event";
          return "Veranstaltungen";
        case "News":
          this.paragraphs =
            "Hier gibt es aktuelle Informationen rund um Veranstaltungen in der Nähe";
          return "News";
        case "News Details":
          this.paragraphs =
            "Hier gibt es aktuelle Informationen rund um Veranstaltungen in der Nähe";
          return "News";
        case "Event Order":
          this.paragraphs = "Bestelle jetzt Deine Eintrittskarte";
          return "Bestellung";
        case "Register":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "Create an account";
        case "Profil":
          this.paragraphs = "Konto Einstellungen";
          return "Mein Profil";
        case "Invoices":
          this.paragraphs = "Überblick Deiner Zahlungen und Rechnungen";
          return "Zahlungen";
        case "Tickets":
          this.paragraphs = "Überblick Deiner Tickets";
          return "Tickets";
        case "Intro":
          this.paragraphs = "So Geht's";
          return "Einführung";
        case "Impressum":
          this.paragraphs = "Rechtliches";
          return "Impressum";
        case "Datenschutz":
          this.paragraphs = "Rechtliches";
          return "Datenschutz";
        default:
          break;
      }
    },
  },
};
</script>
