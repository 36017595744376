import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        eventNotifications: [],
    }
}

const state = getDefaultState()

const getters = {
    eventNotifications: state => {
        return state.eventNotifications
    },
}

const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    eventNotifications: (state, payload) => {
        state.eventNotifications = payload
    },
}

const actions = {

    getEventNotifications: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/eventNotifications', {
                headers: {
                    version: 'v1.1'
                }
            })
            .then(
                res => {

                    if (!res.data.message) {
                        commit('eventNotifications', res.data)
                    }
                    commit('updating', false)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    updateEventNotifications: ({ commit, dispatch }, payload) => {
        let data = JSON.parse(JSON.stringify(payload))
        data.edit = false
        commit('updating', true)
        axiosAuth.patch('/eventNotifications/' + data._id, data)
            .then(
                () => {
                    commit('updating', false)
                    dispatch('getEventNotifications')
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)
                }
            )
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}