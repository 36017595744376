import camelCase from 'lodash/camelCase'
const files = require.context('@/modules', true, /store\/.*.js$/)
const modules = {}

files.keys().forEach(fileName => {
  if (fileName ==='./index.js')
    return
  const moduleName=camelCase(
    fileName.replace(/(\.\/|\.js)/g,'')
  )
  modules[moduleName] = files(fileName).default
  
});

const files_framework = require.context('@/store/modules', true, /.*.js$/)

files_framework.keys().forEach(fileName => {
  if (fileName ==='./index.js')
    return
  const moduleName=camelCase(
    fileName.replace(/(\.\/|\.js)/g,'')
  )
  modules[moduleName] = files_framework(fileName).default
  
});

export default modules