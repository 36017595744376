<template>
  <v-app>
    
    <drawer :drawer="drawer"></drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <app-bar-profile
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        v-if="$route.name != 'Project'"
      ></app-bar-profile>
      <v-container fluid>
        <div
          class="
            min-height-300
            border-radius-xl
            overflow-hidden
            position-relative
            mt-6
            mx-3
          "
          v-bind:style="{
            backgroundImage:
              'url(' + require('@/assets/img/markt-oelsnitz.jpeg') + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        >
          <span class="mask bg-gradient-secondary opacity-6"></span>
          <app-bar-profile-white
            background="bg-transparent"
            has-bg
            @drawer-toggle="drawer = $event"
            :toggle-active="drawer"
            v-if="$route.name == 'Project'"
            :navbarFixed="navbarFixed"
          ></app-bar-profile-white>
        </div>
        <v-card
          class="shadow px-4 py-4 mx-9 overflow-hidden border-radius-xl mt-n16" v-if="userInformations"
        >
          <v-row>
            <v-col cols="auto">
              <v-avatar
                style="cursor: pointer;"
                width="74"
                height="74"
                class="shadow border-radius-lg"
                @click="uploadImageDialog = true"
              >
                <img
                  v-if="userInformations.avatar"
                  :src="getImage(userInformations.avatar)"
                  alt="Avatar"
                  class="border-radius-lg"
                />
                <div v-else>
                  {{ userInformations.firstname.charAt(0)
                  }}{{ userInformations.lastname.charAt(0) }}
                </div>
              </v-avatar>
            </v-col>
            <v-col cols="auto" class="my-auto">
              <div class="h-100 pa-3">
                <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                  {{ userInformations.firstname }}
                  {{ userInformations.lastname }}
                </h5>
                <p class="mb-0 font-weight-light text-body text-sm">
                  {{ userInformations.companyName }}
                </p>
              </div>
            </v-col>
          </v-row>
          <fade-transition
            :duration="200"
            origin="center top"
            mode="out-in"
            v-if="$route.name != 'Project'"
          >
            <!-- your content here -->
            <router-view></router-view>
          </fade-transition>
        </v-card>
        <fade-transition
          :duration="200"
          origin="center top"
          mode="out-in"
          v-if="$route.name == 'Project'"
        >
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </v-container>

      <v-dialog v-model="uploadImageDialog" max-width="500px" v-if="userInformations">
        <v-card class="card-shadow card-padding border-radius-xl">
          <v-card-title
            class="pt-0 text-h5 text-typo font-weight-normal justify-center"
            >Neues Profilbild hochladen</v-card-title
          >
          <v-row justify="center">
            <v-col cols="12" md="8" lg="6" class="my-5">
              <!-- <h6
                class="text-caption text-uppercase font-weight-600 text-muted"
              >
                Neues Profilbild hochladen
              </h6> -->
              <dropzone :key="uploadImageDialog" @newFile="setFile"></dropzone>
            </v-col>
          </v-row>
          <v-card-actions class="pb-0">
            <v-spacer></v-spacer>
            <v-btn
              @click="uploadImageDialog = false"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-normal
                text-capitalize
                btn-ls btn-outline-secondary
                bg-transparent
                py-3
                px-6
              "
              >Abbrechen</v-btn
            >

            <v-btn
              @click="uploadAvatar"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-normal
                text-capitalize
                btn-ls btn-primary
                bg-gradient-primary
                py-3
                px-6
              "
              >Hochladen</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import coreData from "@/mixins/coreDataMixin";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => { 
      initScrollbar(className);
    }, 100);
  }
}

// import DashboardNavbar from './DashboardNavbar.vue';
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
// import AppBarProfile from "@/components/AppBarProfile.vue";
// import AppBarProfileWhite from "@/components/AppBarProfileWhite.vue";
import AppBarProfile from "@/components/AppBar.vue";
import AppBarProfileWhite from "@/components/AppBar.vue";
import ContentFooter from "@/components/Footer.vue";
import Dropzone from "@/views/Components/Dropzone.vue";
import axiosAuth from "@/shared/configs/axios-auth";
export default {
  components: {
    
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBarProfile,
    AppBarProfileWhite,
    Dropzone,
  },
  mixins: [coreData],
  data() {
    return {
      drawer: null,
      uploadImageDialog: false,
      navbarFixed: true,
    };
  },
  methods: {
    setFile(file){
      console.log('set avatar file')
      this.userInformations.file = file
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    // uploadAvatar() {
    //   console.log('uploading avatar')
    //   if(this.userInformations.file){
    //     this.$store.dispatch('updateUserInformations', { file: this.userInformations.file})
    //     this.uploadImageDialog = false
    //   }
    // },
    uploadAvatar() {
      console.log("postevent");
      if (!this.userInformations.file) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("image", this.userInformations.file);
        axiosAuth
          .patch("/authnews/me/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("userInformations");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
            this.uploadImageDialog = false
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            console.log(error.response);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
  mounted() {
    this.initScrollbar();
    this.$store.dispatch("tryAutoLogin");
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
};
</script>
<style lang="scss"></style>
