    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            updatingPayment: false,
            paymentReady: null,
            paymentLink: null,
            paymentId: null,
            paymentState: null
        }
    }

    const state = getDefaultState()

    const getters = {
        updatingPayment: state => {
            return state.updatingPayment;
        },
        paymentReady: state => {
            return state.paymentReady;
        },
        paymentLink: state => {
            return state.paymentLink;
        },
        paymentId: state => {
            return state.paymentId;
        },
        paymentState: state => {
            return state.paymentState;
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        updatingPayment: (state, payload) => {
            state.updatingPayment = payload
        },
        paymentReady: (state, payload) => {
            state.paymentReady = payload
        },
        paymentLink: (state, payload) => {
            state.paymentLink = payload
        },
        paymentId: (state, payload) => {
            state.paymentId = payload
        },
        paymentState: (state, payload) => {
            state.paymentState = payload
        },
    }

    const actions = {

        postPayment({ commit, dispatch }, payload) {
            commit('authError', null)
            commit('authMessage', '')
            commit('updatingPayment', true)
            commit('paymentReady', null)
            commit('paymentId', null)
            axiosAuth.post('/pay-website', payload)
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('paymentReady', true)
                        commit('paymentLink', res.data.link)
                        commit('updatingPayment', false)
                        commit('paymentId', res.data.id)
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updatingPayment', false)
                        commit('paymentReady', null)
                        commit('paymentId', null)
                    }
                )
        },

        checkPayment: ({ commit }, id) => {
            commit('authError', null)
            commit('authMessage', '')
            commit('updatingPayment', true)
            commit('paymentState', null)
            axiosAuth.get('/pay-website/checkPayment/' + id, {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        console.log(res)
                        commit('trash', res)
                        commit('paymentReady', true)
                        commit('paymentState', res.data.state)
                        commit('updatingPayment', false)
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('authMessage', error.response.data)
                        commit('authError', error.response.status)
                        commit('updatingPayment', false)
                    }
                )
        },

    }

    export default {
        state,
        getters,
        mutations,
        actions
    }