    import { dispatch } from 'd3'
    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            eventNews: [],
            news: null,
            loadingEventNews: false,
        }
    }

    const state = getDefaultState()

    const getters = {
        news: state => {
            return state.news
        },
        eventNews: state => {
            return state.eventNews
        },
        loadingEventNews: state => {
            return state.loadingEventNews
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        eventNews: (state, payload) => {
            state.eventNews = payload
        },
        news: (state, payload) => {
            state.news = payload
        },
        loadingEventNews: (state, payload) => {
            state.loadingEventNews = payload
        }
    }

    const actions = {

        getEventNewsByID: ({ commit, dispatch }, id) => {
            commit('loadingEventNews', true)
            axiosAuth.get('/eventNews/byId/' + id, {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingEventNews', false)
                        if (!res.data.message) {
                            dispatch('getNewsComments', res.data._id)
                            commit('news', res.data)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingEventNews', false)

                    }
                )
        },


        getEventNews: ({ commit }) => {
            commit('loadingEventNews', true)
            axiosAuth.get('/eventNews', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingEventNews', false)
                        if (!res.data.message) {
                            commit('eventNews', res.data)
                            dispatch('getEventNewsByID', res.data._id)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingEventNews', false)

                    }
                )
        },

        getEventNewsAdmin: ({ commit }) => {
            commit('loadingEventNews', true)
            axiosAuth.get('/eventNews/admin', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingEventNews', false)
                        if (!res.data.message) {
                            commit('eventNews', res.data)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingEventNews', false)

                    }
                )
        },

        updateEventNews: ({ commit }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            data.edit = false
            commit('updating', true)
            axiosAuth.patch('/eventNews/' + data._id, data)
                .then(
                    () => {
                        commit('updating', false)

                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }