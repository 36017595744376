<template>
  <router-view></router-view>
</template>

<script>
export default {
};
</script>
<style lang="scss">
  @font-face {
  font-family: "Quicksand";
    src: url("./assets/fonts/Quicksand-Regular.ttf");
  }

  @font-face {
  font-family: "Quicksand";
    src: url("./assets/fonts/Quicksand-Regular.ttf");
  }

  /* fallback */
@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/material.woff2") format('woff2');
}

.material-icons-round {
  font-family: 'Material Icons Round';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

</style>