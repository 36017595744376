    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            company: null,
            restaurant: null,
            slots: [],
            loadingRestaurant: null,
            loadingSlots: null
        }
    }
    
    const state = getDefaultState()

    const getters = {
        company: state => {
            return state.company
        },
        restaurant: state => {
            return state.restaurant
        },
        loadingRestaurant: state => {
            return state.loadingRestaurant
        },
        slots: state => {
            return state.slots
        },
        loadingSlots: state => {
            return state.loadingSlots
        },
    }

    const mutations = {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        company: (state, payload) => {
            state.company = payload
        },
        restaurant: (state, payload) => {
            state.restaurant = payload
        },
        loadingRestaurant: (state, payload) => {
            state.loadingRestaurant = payload
        },
        slots: (state, payload) => {
            state.slots = payload
        },
        loadingSlots: (state, payload) => {
            state.loadingSlots = payload
        },
    }

    const actions = {
       
        getCompany: ({commit}) =>{
            commit('updating', true)
            axiosAuth.get('/companies', {
                headers: {
                  version: 'v1.1'
                }
               })
            .then(
                res => {
                    commit('updating', false)
                    if(!res.data.message){
                        commit('company', res.data)
                    }
                }
              )
              .catch(
                error => {
                  console.log(error.response)
                  commit('updating', false)

                }
              )
        },

        checkSlots: ({commit}, payload) =>{
            commit('loadingSlots', true)
            axiosAuth.get('/companies/checkSlots/' + payload.company + '/' + payload.date + '/true')
            .then(
                res => {
                    commit('loadingSlots', false)
                    if(!res.data.message){
                        commit('slots', res.data)
                    }
                }
              )
              .catch(
                error => {
                  console.log(error.response)
                  commit('loadingSlots', false)

                }
              )
        },

        getRestaurant: ({commit}, payload) =>{
            commit('loadingRestaurant', true)
            axiosAuth.get('/companies/' + payload._id)
            .then(
                res => {
                    commit('loadingRestaurant', false)
                    if(!res.data.message){
                        commit('restaurant', res.data)
                    }
                }
              )
              .catch(
                error => {
                  console.log(error.response)
                  commit('loadingRestaurant', false)

                }
              )
        },
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }