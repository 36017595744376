import axiosAuth from '../../../shared/configs/axios-auth'

const getDefaultState = () => {
    return {
        darkMode: true,
        colorSchemeSetByUser: '',
        preferedColorScheme: null,
        storeTest: '',
        popLogin: false,
        popRegister: false,
        updating: false,
        kw: null,
        contactFormSent: false,
        globalCart: [],
        adressData: null,
        adressData_delivery: null,
        appversion: null
    }
}

const state = getDefaultState()

const getters = {
    storeTest: state => {
        return state.storeTest
    },
    preferedColorScheme: state => {
        return state.preferedColorScheme
    },
    darkMode: state => {
        return state.darkMode;
    },
    kw: state => {
        return state.kw;
    },
    colorSchemeSetByUser: state => {
        return state.colorSchemeSetByUser
    },
    testAccess: state => {
        return state.testAccess
    },
    popLogin: state => {
        return state.popLogin
    },
    popRegister: state => {
        return state.popRegister
    },
    updating: state => {
        return state.updating
    },
    contactFormSent: state => {
        return state.contactFormSent
    },
    globalCart: state => {
        return state.globalCart
    },
    adressData: state => {
        return state.adressData
    },
    appversion: state => {
        return state.appversion
    },
    adressData_delivery: state => {
        return state.adressData_delivery
    },
}

const mutations = {
    storeTest(state, payload) {
        state.storeTest = payload
    },
    kw(state, payload) {
        state.kw = payload
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    asideToggled: (state, payload) => {
        state.asideToggled = payload
    },
    testAccess: (state, payload) => {
        state.testAccess = payload
    },
    preferedColorScheme: (state, colorScheme) => {
        state.preferedColorScheme = colorScheme
    },
    darkMode: (state, colorScheme) => {
        state.darkMode = colorScheme
    },
    colorSchemeSetByUser: (state, colorScheme) => {
        state.colorSchemeSetByUser = colorScheme
    },
    popLogin: (state, payload) => {
        state.popLogin = payload
    },
    popRegister: (state, payload) => {
        state.popRegister = payload
    },
    updating: (state, payload) => {
        state.updating = payload
    },
    contactFormSent: (state, payload) => {
        state.contactFormSent = payload
    },
    globalCart: (state, payload) => {
        state.globalCart = payload
    },
    adressData: (state, payload) => {
        state.adressData = payload
    },
    appversion: (state, payload) => {
        state.appversion = payload
    },
    adressData_delivery: (state, payload) => {
        state.adressData_delivery = payload
    },
}

const actions = {
    resetToasts: ({ commit }) => {
        commit('shiftmodelUpdated', null)
    },

    setTheme: ({ commit, dispatch }, colorScheme) => {
        commit('colorSchemeSetByUser', colorScheme)
        dispatch('preferedColorScheme')
    },
    darkMode: ({ commit }) => {
        commit('darkMode')
    },
    preferedColorScheme: ({ commit }) => {
        const preferedColorScheme = window.matchMedia("(prefers-color-scheme: dark)")
        if (!state.colorSchemeSetByUser) {
            if (preferedColorScheme.matches) {
                commit('darkMode', true)
            } else {
                commit('darkMode', false)
            }
        } else {
            if (state.colorSchemeSetByUser == 'dark') {
                commit('darkMode', true)
            } else {
                commit('darkMode', false)
            }
        }
    },

    getAppversion: ({ commit }) => {
        commit('updating', true)
        axiosAuth.get('/appversion')
            .then(
                res => {
                    commit('updating', false)
                    if (!res.data.message) {
                        commit('appversion', res.data)
                    }
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('updating', false)

                }
            )
    },

    sendContactForm({ commit }, payload) {
        commit('contactFormSent', false)
        axiosAuth.post('/contact', payload)
            .then(
                () => {
                    commit('contactFormSent', true)
                }
            )
            .catch(
                error => {
                    console.log(error.response)
                    commit('contactFormSent', false)
                }
            )
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}