const coreData = {
    filters: {

    },
    data() {
        return {
            snackbar: {
                color: null,
                visible: false,
                title: null,
                message: null
            },
            newPushnotification: {}
        }
    },
    computed: {
        rules() {
            return [
                value => !!value || 'Erforderlich.',
                value => (value && value.length >= 3) || 'Min 3 Zeichen',
            ]
        },
        maxSlots() {
            var max = 0;
            if (this.newEvent.sponsors.length > 0) {
                this.newEvent.sponsors.forEach((sponsor) => {
                    max += parseInt(sponsor.slots);
                });
            }
            return max;
        },
        loggedIn() {
            return this.$store.getters.loggedIn;
        },
        darkMode() {
            return this.$store.getters.darkMode;
        },
        eventEntries() {
            var counter = 0;
            if (this.events) {
                this.events.forEach((event) => {
                    if (event.entries) {
                        counter += event.entries.length;
                    }
                });
            }

            return counter;
        },
        contactFormSent() {
            return this.$store.getters.contactFormSent;
        },
        events() {
            return this.$store.getters.events;
        },
        eventComments() {
            return this.$store.getters.eventComments;
        },
        userInformations() {
            return this.$store.getters.userInformations;
        },
        event() {
            if (this.$route.params.id) {
                return this.events.find((el) => el._id == this.$route.params.id);
            }
            return {}
        },
        eventNews() {
            return this.$store.getters.eventNews;
        },
        invoices() {
            return this.$store.getters.invoices;
        },
        eventNotifications() {
            return this.$store.getters.eventNotifications;
        },
        news() {
            if (this.$route.params.id) {
                return this.eventNews.find((el) => el._id == this.$route.params.id);
            }
        },
        newsComments() {
            return this.$store.getters.newsComments;
        },
        interactions() {
            var counter = 0;
            this.events.forEach((event) => {
                counter += event.views;
            });
            this.eventNews.forEach((news) => {
                counter += news.views;
            });
            return counter;
        },
        updating() {
            return this.$store.getters.updating;
        },
        updatingEvents() {
            return this.$store.getters.updatingEvents;
        },
        authError() {
            return this.$store.getters.authError;
        },
        authMessage() {
            return this.$store.getters.authMessage;
        },
        emailResended() {
            return this.$store.getters.emailResended;
        },
        filteredEntries() {
            var preFiltered = [];
            var endFiltered = [];
            if (this.eventBuffer) {
                preFiltered = this.eventEntries.filter(
                    (el) => el.event == this.eventBuffer._id
                );
                endFiltered = preFiltered.filter((el) => this.checkFilter(el));
                return endFiltered;
            }
            return [];
        },
    },

    mounted() {
        if (this.$route.path.includes('admin')) {
            this.$store.dispatch("tryAutoLogin");
            if (!this.loggedIn) {
                this.$router.push("/admin/login");
            } else {
                // this.$store.dispatch("getEventsAdmin");
                // this.$store.dispatch("getEventEntriesAdmin");
                // this.$store.dispatch("getEventNewsAdmin");
                // this.$store.dispatch("getEventNotifications");
            }
        }

    },

    methods: {
        getPaypalState(state) {
            switch (state) {
                case "completed":
                    return "ABGESCHLOSSEN";
                case "refunded":
                    return "ERSTATTET";
                default:
                    return state;
            }
        },
        updateRedeemed(item) {
            item.redeemed = !item.redeemed
            this.updateEventEntry(item)
        },
        SnackbarShow(type, title, message) {
            if (!type) return;
            switch (type) {
                case "Info":
                    this.snackbar = {
                        color: "#1A73E8",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Success":
                    this.snackbar = {
                        color: "#4CAF50",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Warning":
                    this.snackbar = {
                        color: "#fb8c00",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Danger":
                    this.snackbar = {
                        color: "#F44335",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
            }
        },
        getImage(link) {
            return process.env.VUE_APP_API_BASEURL + '/' + link
        },
        csvExportEntries(event) {
            let arrData = event.entries
            let csvData = []

            arrData.forEach(element => {
                let entryData = {
                    "Eventname": event.title,
                    "Rechnungsnummer": element.payment_id[0].invoice_nr,
                    "Ticketpersonalisierung": element.participant_data.name,
                    "Nachname": element.lastname,
                    "Vorname": element.firstname,
                    "Straße": element.street,
                    "PLZ": element.zip.toString(),
                    "E-Mail": element.email,
                    "Rechnungsdatum": element.payment_id[0].update_time,
                    "Transaktionscode": element.payment_id[0].transactions[0].related_resources[0].sale.id,
                    "Ticketpreis in EUR": element.participant_data.price_lowered ? event.price_lowered.toFixed(2).replace(".", ",") : event.price.toFixed(2).replace(".", ","),
                    "Ermäßigt": element.participant_data.price_lowered ? "Ja" : "Nein",
                    "Ticket eingelöst": element.redeemed ? "Ja" : "Nein",
                    "Zeitstempel einlösung": element.redeemed ? element.updated : "n/a"
                }

                csvData.push(entryData)
            })

            // arrData = JSON.parse(JSON.stringify(arrData))
            arrData.forEach(element => {
                if (element.redeemed == undefined) {
                    element.redeemed = false
                }
                delete element.participants;
                delete element.participant_data;
                delete element.edit;
                delete element._id;
                delete element.event;
                delete element.dsgvo_accepted;
                delete element.__v;
                delete element.updated;
            })
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(csvData[0]).join(";"),
                ...csvData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", event.url + "_export.csv");
            link.click();
        },
        updateEventEntry(eventEntry) {
            console.log(eventEntry)
            this.$store.dispatch("updateEventEntry", eventEntry);
            // this.$store.dispatch("getEventEntries");
        },
        updateEventNotification(eventNotification) {
            console.log(eventNotification)
            this.$store.dispatch("updateEventNotifications", eventNotification);
            // this.$store.dispatch("getEventEntries");
        },
        downloadInvoice(invoice) {
            this.$store.dispatch("downloadInvoice", invoice);
        },
        resendEmail(eventEntry) {
            this.$store.dispatch("resendEmail", eventEntry);
        },
        getEventComments(id) {
            this.$store.dispatch("getEventComments", id);
        },
        getNewsComments(id) {
            this.$store.dispatch("getNewsComments", id);
        },
        getInvoices(id) {
            this.$store.dispatch("getInvoices", id);
        },

    }
}

export default coreData