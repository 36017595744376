    import axiosAuth from '../../../shared/configs/axios-auth'

    const getDefaultState = () => {
        return {
            invoices: [],
            loadingInvoices: false,
        }
    }

    const state = getDefaultState()

    const getters = {
        invoices: state => {
            return state.invoices
        },
        loadingInvoices: state => {
            return state.loadingInvoices
        },
    }

    const mutations = {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        invoices: (state, payload) => {
            state.invoices = payload
        },
        loadingInvoices: (state, payload) => {
            state.loadingInvoices = payload
        }
    }

    const actions = {

        getInvoices: ({ commit }, id) => {
            commit('loadingInvoices', true)
            axiosAuth.get('/invoices/' + id, {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingInvoices', false)
                        if (!res.data.message) {
                            commit('invoices', res.data)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingInvoices', false)

                    }
                )
        },

        getInvoicesUser: ({ commit }) => {
            commit('loadingInvoices', true)
            axiosAuth.get('/invoices/user/asd', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingInvoices', false)
                        if (!res.data.message) {
                            commit('invoices', res.data)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingInvoices', false)

                    }
                )
        },

        getAllInvoices: ({ commit }, id) => {
            commit('loadingInvoices', true)
            axiosAuth.get('/invoices', {
                    headers: {
                        version: 'v1.1'
                    }
                })
                .then(
                    res => {
                        commit('loadingInvoices', false)
                        if (!res.data.message) {
                            commit('invoices', res.data)
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('loadingInvoices', false)

                    }
                )
        },

        // getInvoicesAdmin: ({ commit }) => {
        //     commit('loadingInvoices', true)
        //     axiosAuth.get('/invoices/admin', {
        //             headers: {
        //                 version: 'v1.1'
        //             }
        //         })
        //         .then(
        //             res => {
        //                 commit('loadingInvoices', false)
        //                 if (!res.data.message) {
        //                     commit('invoices', res.data)
        //                 }
        //             }
        //         )
        //         .catch(
        //             error => {
        //                 console.log(error.response)
        //                 commit('loadingInvoices', false)

        //             }
        //         )
        // },

        updateInvoices: ({ commit }, payload) => {
            let data = JSON.parse(JSON.stringify(payload))
            data.edit = false
            commit('updating', true)
            axiosAuth.patch('/invoices/' + payload.id, { cancel: true })
                .then(
                    () => {
                        commit('updating', false)
                        dispatch('getInvoices', payload.eventId)
                    }
                )
                .catch(
                    error => {
                        console.log(error.response)
                        commit('updating', false)
                    }
                )
        }
    }

    export default {
        state,
        getters,
        mutations,
        actions
    }