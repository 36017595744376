var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',{staticClass:"auth-pages"},[(
        this.$route.name != 'Event Details' &&
        this.$route.name != 'Event Order'
      )?_c('div',{staticStyle:{"position":"relative","min-height":"50vh"}},[_c('div',{staticClass:"header-auth position-absolute pb-30 pt-30 scroll0 w-100 blur",staticStyle:{"filter":"blur(5px)","-webkit-filter":"blur(5px)","height":"100%"},style:({
          backgroundImage:
            'url(' + require('@/assets/img/markt-oelsnitz.jpeg') + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.4)',
        })}),_c('div',{staticStyle:{"z-index":"2","position":"absolute","height":"100%","width":"100%"}},[_c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"d-flex mt-5",attrs:{"align":"center"}},[_c('v-col',{staticClass:"mx-auto py-0",attrs:{"cols":"12","md":"6"}},[(this.$route.name == 'Pricing')?_c('h2',{staticClass:"text-h2 font-weight-bold text-white text-center mb-2"},[_vm._v(" "+_vm._s(_vm.headerTitle())+" ")]):_c('h1',{staticClass:"text-h3 text-white font-weight-bolder text-center mb-2 mt-5"},[_vm._v(" "+_vm._s(_vm.headerTitle())+" ")]),_c('p',{staticClass:"text-h5 text-white text-center font-weight-thin mb-12"},[_vm._v(" "+_vm._s(_vm.paragraphs)+" ")])])],1)],1)],1)]):_c('div'),_c('app-bar-auth',{attrs:{"login":_vm.loginFromSub,"background":"transparent","has-bg":"","linkColor":"white"}}),_c('v-container',{staticClass:"pb-0 px-0 mt-5",attrs:{"fluid":""}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{on:{"login":function($event){_vm.loginFromSub = true}}}),_vm._v("Ç ")],1),_c('div',{staticClass:"mt-15"},[_c('content-footer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }