<template>
  <v-container>
    <v-row justify="center" class="mb-15">
      <v-card
        class="ma-3 transparent"
        href="https://apps.apple.com/us/app/oelsnitz-ticket/id1617466924"
        target="_blank"
      >
        <v-img
          src="@/assets/img/logos/apple-badge.png"
          alt="logo"
          width="250"
          contain
        ></v-img>
      </v-card>
      <v-card
        class="ma-3 transparent"
        href="https://play.google.com/store/apps/details?id=com.doradoservices.ticketdorado"
        target="_blank"
      >
        <v-img
          src="@/assets/img/logos/google-play-badge.png"
          alt="logo"
          width="250"
          contain
        ></v-img>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppstoresBanner",
  props: {
  },
  data() {
    return {
      

    };
  },
  methods: {
   
  },
};
</script>

<style>
</style>