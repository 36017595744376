<template>
  <v-container>
    <v-row justify="center" class="mb-15">
      <v-col cols="12" md="6">
        <v-img
          src="@/assets/img/oelsnitz-ticket-phone-combo-shadow.png"
          alt="logo"
          width="100%"
          contain
        ></v-img>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <h2 class="mx-3 h1 font-weight-normal text-center mb-5 text-h4 mt-10">
          Lade die Oelsnitz-Ticket App für Dein Smartphone herunter
        </h2>
  
          
          <div class="text-start mt-1 text-h5 mb-2">
            <v-icon size="25" class="text-warning mr-4">fas fa-check</v-icon>
            Tickets immer dabei
          </div>
    
          
          <div class="text-start mt-1 text-h5 mb-2">
            <v-icon size="25" class="text-warning mr-4">fas fa-check</v-icon>
            Erfahre als erster von neuen Events
          </div>
 

          
          <div class="text-start mt-1 text-h5 mb-5">
            <v-icon size="25" class="text-warning mr-4">fas fa-check</v-icon>
            Bequem online buchen
          </div>

        <v-row justify="center" class="mb-15">
          <v-col cols="6">
            <v-card
              class="transparent"
              href="https://apps.apple.com/us/app/oelsnitz-ticket/id1617466924"
              target="_blank"
            >
              <v-img
                src="@/assets/img/logos/apple-badge.png"
                alt="logo"
                width="100%"
                contain
              ></v-img>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              class="transparent"
              href="https://play.google.com/store/apps/details?id=com.doradoservices.ticketdorado"
              target="_blank"
            >
              <v-img
                src="@/assets/img/logos/google-play-badge.png"
                alt="logo"
                width="100%"
                contain
              ></v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppstoresTeaser",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
</style>